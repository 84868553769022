/* Global Styles */
body {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  /* Set font family for the body */
}

header,
footer {
  background: #202936;
  /* Set background color for header and footer */
  color: white;
  /* Set text color for header and footer */
}

html {
  position: relative;
  min-height: 100%;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

a {
  text-decoration: none;
}

/* Navigation Styles */
nav a.nav-link {
  color: white;
  /* Set default color for navigation links */
  position: relative;
  /* Set position to relative to make the ::before pseudo-element relative to this */
}

nav a.nav-link:hover,
nav a.nav-link:focus,
.dropdown button.btn:hover,
.dropdown button.btn:focus {
  color: #F85546;
  /* Change color on hover/focus for navigation links and buttons */
  background: none;
  /* Remove background on hover/focus for navigation links and buttons */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #F85546;
  text-transform: uppercase;
  text-align: center;
}

.error-message {
  width: 100%;
  text-align: center;
  background: #f8554673;
  padding: 5px;
  border-radius: 5px;
}

nav a.nav-link::before,
.dropdown button.btn::before {
  content: "";
  /* Add pseudo-element */
  height: 2px;
  /* Set height for underline */
  border-radius: 0;
  /* Set border radius for underline */
  background-color: #F85546;
  /* Set color for underline */
  position: absolute;
  /* Set position to absolute */
  bottom: 0;
  /* Position the underline at the bottom of the link */
  left: 0;
  /* Set left position */
  right: 0;
  /* Set right position */
  transform: scaleX(0);
  /* Initial transform to hide the underline */
  transform-origin: left center;
  /* Set transform origin */
  transition: transform 0.5s ease;
  /* Add transition effect */
}

nav a.nav-link:hover::before {
  transform: scaleX(1);
  /* Scale the underline to show it on hover */
}

/* Other Styles... */

.navbar-toggler {
  background: white;
  /* Set background color for navbar toggler */
}

.dropdown button.btn {
  background: none;
  /* Remove background for dropdown button */
  border: none;
  /* Remove border for dropdown button */
}

.dropdown-menu.show {
  min-width: 100%;
  /* Set minimum width for dropdown menu */
}

.dropdown-menu.show a:hover {
  color: #F85546;
  /* Change color on hover for dropdown menu links */
}

.carousel .slide .legend {
  transition: all .5s ease-in-out;
  /* Add transition effect for carousel legend */
  position: absolute;
  /* Set position to absolute */
  bottom: 40px;
  /* Set bottom position for carousel legend */
  left: 0;
  /* Set left position for carousel legend */
  margin-left: 0;
  /* Set margin left for carousel legend */
  width: 100%;
  /* Set width for carousel legend */
  background: #00000070;
  /* Set background color for carousel legend */
  color: #fff;
  /* Set text color for carousel legend */
  padding: 10px;
  /* Set padding for carousel legend */
  font-size: 12px;
  /* Set font size for carousel legend */
  text-align: center;
  /* Set text alignment for carousel legend */
  opacity: 1;
  /* Set opacity for carousel legend */
  transition: opacity .35s ease-in-out;
  /* Add transition effect for opacity */
}

.carousel .slide .legend p a,
.banner p a {
  border: 2px solid #fff;
  /* Set border for links */
  color: #fff;
  /* Set text color for links */
  background: rgba(5, 6, 1, .51);
  /* Set background color for links */
  padding: 10px 23px;
  /* Set padding for links */
  cursor: pointer;
  /* Set cursor type for links */
  outline: 0;
  /* Remove outline for links */
  text-align: center;
  /* Set text alignment for links */
  -webkit-border-radius: 0;
  /* Set border radius for links */
  -moz-border-radius: 0;
  /* Set border radius for links */
  border-radius: 0;
  /* Set border radius for links */
  text-transform: uppercase;
  /* Set text transformation for links */
  font-weight: 700;
  /* Set font weight for links */
}

.banner {
  width: 100%;
  /* Set width for banner */
  background: #fa5543e0;
  /* Set background color for banner */
  display: flex;
  /* Set display type for banner */
  align-items: center;
  /* Set alignment for banner */
  justify-content: center;
  /* Set alignment for banner */
  padding: 10px;
  /* Set padding for banner */
  position: relative;
  /* Set position to relative for banner */
  flex-wrap: wrap;
  /* Set flex wrap for banner */
}

.banner p {
  color: #fff;
  /* Set text color for paragraph */
  font-weight: 600;
  /* Set font weight for paragraph */
}

.banner h2 {
  color: #202936;
  /* Set text color for heading */
}

.banner p span {
  color: #202936;
  /* Set text color for span */
  font-weight: 800;
  /* Set font weight for span */
}

.banner .img img {
  height: 220px;
  /* Set height for image */
  width: 230px;
  /* Set width for image */
}

.banner .text {
  margin-left: 4rem;
  /* Set margin left for text */
}

/* Main Information Section */
main .info {
  background-image: url("../images/onlypizza_parallex42_black.jpeg");
  /* Set background image */
}

.info-odd-bg {
  background: #fff;
  /* Set background color for odd background */
  color: #202936;
  /* Set text color for odd background */
  padding: 30px;
  /* Set padding for odd background */
}

.info-even-bg {
  background: #202936;
  /* Set background color for even background */
  color: #fff;
  /* Set text color for even background */
  padding: 30px;
  /* Set padding for even background */
}

/* Category Area */
.category-area {
  width: 100%;
  /* Set width for category area */
  display: flex;
  /* Set display type for category area */
  align-items: center;
  /* Set alignment for category area */
  justify-content: flex-start;
  /* Set alignment for category area */
  flex-direction: column;
  /* Set flex direction for category area */
}

.category-area .category {
  width: 100%;
  /* Set width for category */
  display: flex;
  /* Set display type for category */
  align-items: center;
  /* Set alignment for category */
  justify-content: center;
  /* Set alignment for category */
  margin-top: 2rem;
  /* Set margin top for category */
  flex-wrap: wrap;
  /* Set flex wrap for category */
}

.category-area .category .cat-icon {
  display: flex;
  /* Set display type for category icon */
  align-items: center;
  /* Set alignment for category icon */
  justify-content: center;
  /* Set alignment for category icon */
  flex-direction: column;
  /* Set flex direction for category icon */
  padding: 10px;
  /* Set padding for category icon */
  width: 90px;
  /* Set width for category icon */
  border-radius: 10px;
  /* Set border radius for category icon */
  background-color: #F85546;
  /* Set background color for category icon */
  margin: 2rem;
  /* Set margin for category icon */
  transition: all 0.3s ease-in-out;
  /* Add transition effect for category icon */
  cursor: pointer;
  /* Set cursor type for category icon */
  color: #fff;
  /* Set text color for category icon */
}

.category-area .category .cat-icon.active,
.category-area .category .cat-icon:hover {
  background: #202936;
  /* Change background color on hover for category icon */
  color: #fff;
  /* Change text color on hover for category icon */
  transition: all 0.3s ease-in-out;
  /* Add transition effect on hover for category icon */
}

.category-area .category .cat-icon .img {
  padding: 10px;
  /* Set padding for category icon image */
  border-radius: 50%;
  /* Set border radius for category icon image */
  background: #fff;
  /* Set background color for category icon image */
  display: flex;
  /* Set display type for category icon image */
  align-items: center;
  /* Set alignment for category icon image */
  justify-content: center;
  /* Set alignment for category icon image */
  margin: 5px 0px;
  /* Set margin for category icon image */
}

.category-area .category .cat-icon img {
  width: 30px;
  /* Set width for category icon image */
  height: 30px;
  /* Set height for category icon image */
}

/* Authentication Section */
.auth {
  display: flex;
  /* Set display type for authentication */
  align-items: center;
  /* Set alignment for authentication */
  justify-content: center;
  /* Set alignment for authentication */
  width: 100%;
  /* Set width for authentication */
  position: relative;
  /* Set position to relative for authentication */
  min-height: 100vh;
}

.auth .logo {
  position: relative;
  /* Set position to relative for logo */
  top: -4rem;
  /* Set top position for logo */
}

.auth .logo img {
  width: 100px;
  /* Set width for logo image */
  height: 100px;
  /* Set height for logo image */
}

.form {
  width: 360px;
  /* Set width for form */
  background: #fff;
  /* Set background color for form */
  padding: 15px;
  /* Set padding for form */
  border-radius: 6px;
  /* Set border radius for form */
  display: flex;
  /* Set display type for form */
  flex-direction: column;
  /* Set flex direction for form */
  align-items: center;
  /* Set alignment for form */
  justify-content: flex-start;
  /* Set alignment for form */
  box-shadow: 0 8px 16px rgb(0 0 0 / 8%);
  /* Add box shadow for form */
}

.form form {
  width: 100%;
  /* Set width for form */
  display: flex;
  /* Set display type for form */
  flex-direction: column;
  /* Set flex direction for form */
  align-items: center;
  /* Set alignment for form */
  justify-content: center;
  /* Set alignment for form */
}

form input,
form select,
form textarea {
  border: none;
  /* Remove border for input/select */
  outline: none;
  /* Remove outline for input/select */
  padding: 12px 10px;
  /* Set padding for input/select */
  background: rgb(241, 241, 241);
  /* Set background color for input/select */
  width: 100%;
  /* Set width for input/select */
  border-radius: 3px;
  /* Set border radius for input/select */
  margin: 10px 0;
  /* Set margin for input/select */
  color: #484848;
  /* Set text color for input/select */
}

form .text {
  width: 100%;
  /* Set width for text */
  text-align: end;
  /* Set text alignment for text */
  font-size: 15px;
  /* Set font size for text */
  padding: 10px 0;
  /* Set padding for text */
}

form .text a {
  color: #F85546;
  /* Set text color for text link */
}

form button {
  width: 100%;
  /* Set width for button */
  border-radius: 5px;
  /* Set border radius for button */
  margin: 20px 0;
  /* Set margin for button */
  display: flex;
  /* Set display type for button */
  align-items: center;
  /* Set alignment for button */
  justify-content: center;
  /* Set alignment for button */
  background: #202936;
  /* Set background color for button */
  color: #fff;
  /* Set text color for button */
  border: none;
  /* Remove border for button */
  padding: 5px;
  /* Set padding for button */
  text-transform: uppercase;
}

.forget {
  width: 100%;
  /* Set width for forget */
  display: flex;
  /* Set display type for forget */
  align-items: center;
  /* Set alignment for forget */
  justify-content: center;
  /* Set alignment for forget */
}

.forget a {
  color: #F85546;
  /* Set text color for forget link */
}

.err {
  color: #F85546;
  /* Set text color for error message */
  width: 100%;
  /* Set width for error message */
  padding: 5px 8px;
  /* Set padding for error message */
  border-radius: 3px;
  /* Set border radius for error message */
  background: rgba(243, 79, 79, 0.295);
  /* Set background color for error message */
  text-align: start;
  /* Set text alignment for error message */
  font-size: 12px;
  /* Set font size for error message */
  font-weight: bold;
  /* Set font weight for error message */
}

.input-group {
  position: relative;
  /* Set position to relative for input group */
  margin-bottom: 20px;
  /* Set margin bottom for input group */
}

input:focus {
  border-bottom-color: #202936;
  /* Change border bottom color on focus for input */
}

label {
  position: absolute;
  /* Set position to absolute for label */
  left: 10px;
  /* Set left position for label */
  top: 50%;
  /* Set top position for label */
  transform: translateY(-50%);
  /* Move label up by 50% of its height */
  pointer-events: none;
  /* Disable pointer events for label */
  transition: top 0.3s ease, font-size 0.3s ease, color 0.3s ease;
  /* Add transition effect for label */
}

.input-group input::placeholder {
  opacity: 0;
  /* Set opacity to 0 for input placeholder */
}

.input-group input:focus+label,
.input-group input:not(:placeholder-shown)+label {
  top: 10px;
  /* Set top position for label */
  font-size: 14px;
  /* Set font size for label */
  color: #202936;
  /* Set text color for label */
}

/* Profile Section */
.profile-section {
  width: 100%;
  /* Set width for profile section */
  display: flex;
  /* Set display type for profile section */
  align-items: center;
  /* Set alignment for profile section */
  justify-content: flex-start;
  /* Set alignment for profile section */
  flex-direction: column;
  /* Set flex direction for profile section */
  background: #fff;
  /* Set background color for profile section */
  padding: 60px 20px;
  /* Set padding for profile section */
}

.profile-photo {
  width: 200px;
  /* Set width for profile photo */
  height: 200px;
  /* Set height for profile photo */
  background: #202936;
  /* Set background color for profile photo */
  display: flex;
  /* Set display type for profile photo */
  align-items: center;
  /* Set alignment for profile photo */
  justify-content: center;
  /* Set alignment for profile photo */
  border-radius: 6px;
  /* Set border radius for profile photo */
}

.profile-photo h1 {
  color: #fff;
  /* Set text color for profile photo */
  font-size: 4rem;
  /* Set font size for profile photo */
}

.profile-detail {
  margin-top: 30px;
  /* Set margin top for profile detail */
  width: 100%;
  /* Set width for profile detail */
}

.profile-detail .profile-input {
  width: 60%;
  /* Set width for profile input */
  margin: 0 auto;
  /* Set margin for profile input */
}

.profile-detail .profile-input input {
  width: 100%;
  /* Set width for input inside profile input */
}

.profile-detail button {
  width: 60%;
  /* Set width for button inside profile detail */
  margin: 0 auto;
  /* Set margin for button inside profile detail */
  margin-top: 20px;
  /* Set margin top for button inside profile detail */
}

/* skeleton css */
/* basic style */

.skeleton {
  background: #ddd;
  margin: 10px 0;
  border-radius: 4px;
}

.skeleton.text {
  width: 100%;
  height: 12px;
}

.skeleton.title {
  width: 50%;
  height: 20px;
  margin-bottom: 15px;
}

.skeleton.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.skeleton.thumbnail {
  width: 100px;
  height: 100px;
}

.skeleton-wrapper {
  margin: 20px 0;
  padding: 10px 15px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}

/* skeletonprofile */
.skeleton-profile {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 30px;
  align-items: center;

}

/* themes */
.skeleton-wrapper.light {
  background: #f2f2f2;
}

.skeleton-wrapper.dark {
  background: #444;
}

.skeleton-wrapper.dark .skeleton {
  background: #777;
}

/* animation */
.shimmer-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: loading 2s infinite;
}

.shimmer {
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.4);
  transform: skewX(-20deg);
  box-shadow: 0 0 30px 30px rgba(255, 255, 255, 0.05);
}

.dark .shimmer {
  background: rgba(255, 255, 255, 0.05);
}

@keyframes loading {
  0% {
    transform: translateX(-150%);
  }

  50% {
    transform: translateX(-60%);
  }

  100% {
    transform: translateX(150%);
  }
}


/* Manu page */

.box-shadow {
  background: rgba(255, 255, 255, 0.90);
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.07),
    0 15px 4px 0 rgba(50, 50, 93, 0.01);
  border-radius: 4px;
}


#takeaway_list .cuisine-box {
  align-items: center;
  border-radius: 4px;
  color: #111;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  height: 85px;
  justify-content: center;
  line-height: normal;
  margin-bottom: 7px;
  min-width: 100px;
  position: relative;
  text-align: center
}

#cuisines-items .cat-icon {
  width: 100%;
}

#takeaway_list .cuisine-box .select-icon {
  height: 20px;
  left: 2px;
  position: absolute;
  top: 2px;
  transform: scale(0);
  transition: all .2s ease-in-out;
  width: 22px
}

#takeaway_list .cuisine-box .select-icon.selected {
  transform: scale(1)
}

#takeaway_list .cuisine-filter .cuisine-box .cuisine-title {
  line-height: 18px;
  margin-top: auto
}

#takeaway_list .cuisine-box .cuisine-title .cuisine-count {
  padding-left: 3px
}

#takeaway_list .cuisine-box .cuisine-title.selected {
  font-weight: 600;
  letter-spacing: -.2px
}

#takeaway_list .cuisine-box .select-icon svg {
  fill: #fff
}

#takeaway_list .cuisine-box>img {
  max-height: 84px
}

#takeaway_list .cuisine-box .image-container {
  max-height: 45px;
  overflow: hidden;
  position: relative
}

#takeaway_list .cuisine-box .image-container>img {
  width: 100%
}

#takeaway_list .cuisine-box .image-container .overlay {
  background: rgba(4, 4, 4, .6);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%
}

#takeaway_list .cuisine-box .image-container .overlay>img {
  left: 5px;
  max-height: 19px;
  position: absolute;
  top: 5px
}

#takeaway_list .cuisine-box+.cuisine-title {
  color: #111;
  font-size: 14px;
  font-weight: 500;
  line-height: 13.33px;
  margin-bottom: 0
}

#takeaway_list .cuisine-mobile .cuisine-box+.cuisine-title {
  margin-bottom: 15px
}

.all-products {
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 20px 0;
}

.all-products .product-card {
  width: 200px;
  padding: 15px;

  border-radius: 10px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 0 5px;
  margin-top: 20px;
  position: relative;
}

.all-products .product-card.product-list {
  justify-content: space-between;
  width: 25%;
}

.all-products .product-card.product-list button {

  border-radius: 5px;
  border: none;
  padding: 10px;
  margin-top: 10px;
  background: #F85546;
  color: #fff;
  box-shadow: 0px 0px 4px #F85546;
}

.all-products .product-card.product-list button.edit {


  background: #202936;
  color: #fff;
  box-shadow: 0px 0px 4px #202936;
}

.product-card .specialInstruction {
  color: #fff;
  font-family: cursive;
  font-weight: 400;
  text-transform: capitalize;
  padding: 5px 10px;
  background: #202936;
  box-shadow: 0 5px 15px 0 rgb(32 41 54 / 70%), 0 15px 4px 0 rgb(32 41 54 / 1%);
  border-radius: 4px;
}

.product-card .love {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 7px;
  background: rgba(241, 115, 115, 0.34);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}

.product-card .love svg {
  font-size: 18px;
  font-weight: 600;
  color: #F85546;

}

.product-card .img {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-card .add-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F85546;
  color: #fff;
  padding: 10px;
  border-radius: 50%;
  box-shadow: 2px 2px 10px #f48f0156;
  cursor: pointer;
}

.all-products .product-card img {
  width: 150px;
  height: auto;
}

.all-products .product-card .des {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.product-card .des h3 {
  color: #202936;
  font-size: 16px;
  text-transform: initial;
  text-align: left;
}

.product-card .des .starts svg {
  font-size: 13px;
  color: #F85546;

}

.product-card .des .price {
  font-size: 16px;
}

.product-card .des .price span {
  font-size: 12px;
  color: #F85546;

}

.search-bar input {
  padding: 2px 0;
}



/* cart */


.leftside {
  height: 100%;
  background: #fff;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0px 20px;
}

.leftside .header {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.header .icon {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #F85546;
  transition: all 0.3s ease-in-out;
  position: relative;
}

.header .icon span {
  padding: 3px 10px;
  border-radius: 50%;
  position: absolute;
  background: #F85546;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  right: 0;
  top: -3px;
  box-shadow: 0px 0px 3px #F85546;
}

.header .icon:hover {
  background: #F85546;
  color: #fff;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 0px 10px #F85546;
}

.header .icon svg {
  font-size: 26px;
  font-weight: bold;
}

.all-items.side-cart h2 {
  font-size: 15px;
}

.all-items.side-cart h3 {
  font-size: 15px;
  color: #000;
}

.all-items.side-cart .img img {
  height: 50px;
  width: 50px;
}

.all-items.side-cart .cart-card {
  padding: 10px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.151);
}

.side-cart-area {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  flex-direction: column;
}

.side-cart-area .text {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.side-cart-area .cart-area {
  width: 100%;
}

.side-cart-area .text p {
  color: #202936;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
}

.side-cart-area .text p svg {
  margin-left: 10px;
}

.wishlist_main {
  min-height: 100vh;
}

.side-cart-area button {
  width: 100%;
  border-radius: 5px;
  border: none;
  padding: 10px;
  margin-top: 10px;
  background: #202936;
  color: #fff;
  box-shadow: 0px 0px 4px #202936;
}

.user-info {
  margin-right: 20px;
}

.user-profile-icon {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: #F85546;
  color: #fff;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar-msg {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  padding: 20px;
}

.sidebar-msg h2 {
  color: #F85546;
}

.sidebar-msg h2 span {
  color: #F85546;
}

.sidebar-msg .img img {
  width: 300px;
}

.waring-noti {
  position: fixed;
  top: 80px;
}

.waring-noti div {
  background: rgba(255, 0, 0, 0.411);
  color: #fff;
  font-size: 17px;
  border-radius: 6px;
  padding: 8px;

  margin: 10px;

}


.cart-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  background: rgb(241, 241, 241);
  min-height: 100vh;
}

.cart-screen img {
  height: 500px;
}

.cart-screen p {
  margin: 10px 0;
}

.cart-screen button {
  width: 100%;
  border-radius: 5px;
  border: none;
  padding: 10px;
  margin-top: 10px;
  background: #202936;
  color: #fff;
  box-shadow: 0px 0px 4px #202936;
}

.cart-area {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.side-cart-area .all-items {
  width: 100%;
}

.cart-area .cart-card {
  padding: 20px 20px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
  background: #fff;
  box-shadow: 0 4px 12px 0 rgb(0 0 0 / 5%);
}

.cart-area .cart-card .img {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: #f48f0159;
  border-radius: 20px;
  padding: 10px;
  margin-right: 20px;
}

.cart-card .img img {
  height: 100px;
  width: 100px;
  border-radius: 20px;
}

.cart-card .price {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}

.cart-card .handle {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cart-card .handle .btn {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F85546;
  border-radius: 3px;
  color: #fff;
  box-shadow: 0px 0px 4px #F85546;
  cursor: pointer;
}

.cart-card .handle .qty {
  display: flex;
  align-items: center;
  justify-content: center;
}

.qty .item-qty {
  margin: 0 5px;
  width: 30px;
  text-align: center;
  padding: 0 5px;
  border: 1px solid #F85546;
}

.handle .delete {
  padding: 5px;
  border-radius: 3px;
  color: #F85546;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  background: rgba(255, 0, 0, 0.39);
  font-size: 18px;
}

.cart-card .price span {
  font-size: 18px;
  color: #202936;
}

.billing {
  padding: 30px;
  background: #fff;
  width: 400px;
  box-shadow: 0 4px 12px 0 rgb(0 0 0 / 5%);
  border-radius: 6px;
  margin-top: 20px;
  margin-left: 40px;
  margin-bottom: 30px;
}

.billing h4 {
  color: #F85546;
  width: 100%;
  border-bottom: 1px dotted #F85546;
  padding-bottom: 20px;
}

.billing span {
  color: #202936;
}

.billing .details {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.billing .details .item,
.total {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.total {
  color: rgb(100, 100, 100);
  margin-top: 20px;
  border-top: 1px dotted #F85546;
}

.billing .details .item p {
  color: rgb(87, 86, 86) !important;
  font-size: 17px;
}

.checkout-area {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.checkout-area button {
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-left: 40px;
  margin-top: 10px;
  background: #202936;
  color: #fff;
  box-shadow: 0px 0px 4px #202936;
  border: none;
  padding: 10px;
}

.free {
  color: #388e3c !important;
  text-transform: uppercase;
  font-weight: 600;
}


.shipping {
  background: rgb(241, 241, 241);
  width: 100%;
  padding-bottom: 3rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.shipping-details {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 80%;
}

.address {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 55%;
}

.add-sec-area {
  width: 100%;
}

.shipping-details .checkout-area {
  width: 45%;
}

.og-add {
  width: 100%;
  height: auto;
  box-shadow: 0 4px 12px 0 rgb(0 0 0 / 5%);
  background: #fff;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 20px;
  margin: 20px 0;
  border-radius: 3px;
}

.og-add p {
  margin-bottom: 5px;
}

.og-add span {
  color: #202936;
  font-size: 14px;
  margin: 3px 0;
}

.og-add .btns {
  margin-top: 10px;
  width: 100%;
}


.og-add .btns button.edit {
  border-radius: 5px;
  border: none;
  padding: 10px;
  margin-top: 10px;
  background: #202936;
  color: #fff;
  box-shadow: 0px 0px 4px #202936;
  margin-right: 10px;
  width: 45%;
  text-transform: uppercase;
}

.og-add .btns button.remove {

  border-radius: 5px;
  border: none;
  padding: 10px;
  margin-top: 10px;
  background: #F85546;
  color: #fff;
  box-shadow: 0px 0px 4px #F85546;
  width: 45%;
  text-transform: uppercase;
}


.add-address {
  padding: 20px;
  border: 1px dashed rgb(163, 163, 163);
  background: #fff;
  border-radius: 3px;

  cursor: pointer;
}

.add-address .add {
  color: #F85546;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.add p {
  font-weight: 600;
}

.add svg {
  font-size: 28px;
}

.progress {
  width: 100%;
  background: #fff;
  padding: 20px;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.status {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.status p {
  margin: 0 10px;
  color: #F85546;
}

.status p.active {
  color: #F85546;
  border-bottom: 2px solid #F85546;
}

.status .divider {
  display: inline-block;
  border-top: 1px dashed #696b79;
  height: 4px;
  width: 10%;
  margin-top: 5px;
}

.address-form {
  width: 400px;
  padding: 20px;
  background: #fff;
}

.address-form .submit-btn {
  width: 100%;
}

.address-form button {
  width: 100%;
  border-radius: 3px;
  padding: 10px 20px;
  margin-top: 20px;
}

.selected {
  border: 2px solid #F85546;
}

.payments-opts {
  width: 100%;
  background: #ffffff;
  padding: 20px;
  margin-top: 20px;
}

.select-opt {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 15px;
  background: #eaeaec;
  margin: 10px 0;
  position: relative;
}

.select-opt label {
  margin-left: 10px;
  position: relative;
  top: 0;
  left: 0;
  transform: none;
}

.address .cart-area {
  padding: 0px;
  width: 100%;
}

.address .cart-area .all-items {
  width: 100%;
}

.address .cart-area .cart-card {
  width: 100%;
  border-radius: 3px;
  justify-content: flex-start;
}

.address .cart-area .cart-card .img {
  background: #eaeaec;
  border-radius: 3px;
}

.address .cart-area .cart-card .img img {
  border-radius: 3px;
}

.address .cart-area .cart-card p {
  color: #202936;

}

.address .cart-area .cart-card span {
  color: #F85546;
}

.address .cart-area .cart-card p.des {
  font-size: 13px;
  padding-top: 10px;
}

.shipping .skeleton-wrapper {
  margin-top: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.shipping .skeleton.title {
  height: 42px;
}

.shipping .billing {
  margin-bottom: 20px;
}


/* modal */

.black-scree {
  width: 100%;
  height: 100%;
  background-color: rgba(24, 24, 24, 0.345);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
}

.black-scree.show {
  opacity: 1;
  visibility: visible;
  transition: all 0.5s ease-in-out;
  animation: popup 0.7s;
}

.message-box {
  width: 300px;
  height: 300px;
  padding: 20px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 6px;
}

.message-box img {
  height: auto;
  width: 100%;

}

.message-box p {
  margin: 15px 0;
  font-family: 600;
}

.message-box p.error {
  color: #F85546;
}

.message-box p.success {
  color: green;
}

.message-box button {

  width: 100%;
  border-radius: 5px;
  background: #202936;
  color: #ffffff;
}


@keyframes popup {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.4);
  }

  100% {
    transform: scale(1);
  }
}

.all-orders-area {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.display-orders {
  width: 100%;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 30px;
  flex-wrap: wrap;
}

.order-detail-card {
  width: 410px;
  background: #fff;
  margin: 20px 0px;
  padding: 20px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 220px;
  overflow: scroll;
  align-items: flex-start;
}

.order-detail-card img {
  height: 70px;
  width: 70px;
}

.order-detail-card .details {
  margin-left: 30px;
}

.order-detail-card .details h4 {
  color: #F85546;
}

.order-detail-card .details p {
  color: #202936;
}

.order-detail-card .status {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
}

.order-detail-card .status span {
  color: rgb(10, 190, 10);
}

.order-left-details {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.image-card {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 0;
}

.display-orders {
  margin-bottom: 50px;
}

/* about */
.about .category-area .category .cat-icon {
  margin: 1em;
}

/* Media Query for Small Screens */
@media (max-width: 992px) {
  .dropdown-menu.show {
    background: none;
    /* Remove background for dropdown menu */
    border: none;
    /* Remove border for dropdown menu */
  }

  .dropdown-menu.show a {
    color: #fff;
    /* Set text color for dropdown menu links */
  }

  .all-products .product-card {
    width: 100%;
  }

  .all-products .product-card.product-list {
    width: 40%;
  }

  #cuisines-items .cat-icon {
    width: 25%;
  }

  .cart-area .all-items {
    width: 100%;
  }

  .checkout-area {
    width: 100%;
  }

  .billing {
    width: 100%;
    margin-left: 0;
  }

  .checkout-area button {
    margin-left: 0;
    width: 100%;
  }

  .shipping-details {
    gap: 30px;
    width: 90%;
  }

  .display-orders {
    justify-content: center;
  }
}

@media (max-width: 767px) {

  .leftside {
    height: auto;
  }

  .all-products .product-card.product-list {
    width: 100%;
  }

  #cuisines-items .cat-icon {
    width: 45%;
  }

  .checkout-area {
    width: 100%;
  }

  .shipping-details {
    flex-direction: column;
    width: 100%;
    padding: 10px 20px;
  }

  .address,
  .shipping-details .checkout-area {
    width: 100%;
  }

  .display-orders {
    flex-direction: column;
  }

  .shipping-details .cart-area .cart-card {
    flex-direction: column;
  }

  .display-orders {
    justify-content: center;
  }

  .order-detail-card {
    width: 100%;
  }
}